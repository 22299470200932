@import "./variables.scss";
@import "./functions.scss";
@import "./design-measures.scss";

//responsive classes
#home-toolbar {
  @include responsive-class('home-toolbar');
}

.ht-button {
  @include responsive-class('ht-button');
}

.ht-icon {
  @include responsive-class('ht-icon');
}

.ht-button path {
  @include responsive-class('ht-button-svg');
}

.home-tiles {
  @include responsive-class('home-tiles');
}

.home-tile {
  @include responsive-class('home-tile');
  @include responsive-class('home-tile-legend');

  @include responsive-class('home-tile-legend-icon');
  @include responsive-class('home-tile-legend-label');
}

#home-content {
  @include responsive-class('home-content');
}

.MuiListItem-root {
  @include responsive-class('menu-icon');
  @include responsive-class('menu-icon-svg');
}

.menu-items-container .menu-text {
  @include responsive-class('menu-text');
}

.menu-st0 {
  @include responsive-class('menu-icon-svg');
}

//non responsive classes

.list-component li {
  border: none;
  border-bottom: 1px solid $listBorderColor;
}

.list-el-text {
  font-weight: bold;
}

.list-el-event .start-date {
  color: $fontColor;
}

.text-minor {
  font-weight: normal;
}



.toolbar>.center {
  //  padding-top: 0.2em;
}

.type-bar-label {
  padding-top: 3px;
  font-weight: bold !important;

  small {
    font-weight: normal !important;
  }
}

.is-android .type-bar-count {
  padding: 0 4px 0 3px;
}

// Category counter
.is-android .list-el-counter>span {}

.is-ie .list-el-counter>span {}

.list-el-counter>span {
  padding: 0.09em;
}

.ad-banner-container {}

.home-tile-subcontainer {
  margin: 0px !important;
  padding: 0px;
}

.home-tile-subcontainer {
  // margin: -10px !important;
  padding: 0px;
}




.list-component.speakers {
  .itemWrapper {
    order: 2;
  }

  // NB: flex `order` default value is 0
  .list-el-image {}

  .list-el-chevron {
    order: 3;
  }
}

.list-component.participants {
  .list-el-image {
    max-width: 40px;
  }
}

.fa-lang:before {
  // content: "\f0ac";
}

.fa-cgu:before {
  // content: "\f15c";
}

.fa.connected {
  //  color: #a1c308;
}

.mobilespot-bar {
  display: none;
  justify-content: end;
  color: #ffffff;
  height: 16px;
  visibility: hidden; // keep bar but hide it
  // background:$color1 3px center / 40px no-repeat url('/files/project/home/Ademe_logo.png');
  display: none;

  & .link.terms-of-use {
    color: #ffffff;
  }
}
.colored-link {
  color: #0089d9 !important;
}
.choose-profile-button {
  border-radius: 5px;
}

.logo.img-full-width {
  // margin: 20px;
}

.title-image {
  flex-basis: 45px;
  background-size: contain;
  background-position-y: center;
  border-radius: 0px;
  height: 45px;
  margin: 0 0.2em 0 0;
}

.title-image-small {
  flex-basis: 24px;
  background-size: contain;
  background-position-y: center;
  border-radius: 0px;
  height: 24px;
  margin: 0 0.1em 0 0;
}

.detail-speakers-component .type-bar.horizontal-separator img {
  height: 50px;
}

.type-bar-content {
  transition: max-height 0.5s, opacity 0.5s;
}

.fiche-row1 {
  margin-bottom: 1.5em;
}

.fiche .fiche-row1 {
  margin-bottom: 0.5em;
}

// USER DATA (profile)

.ud-section-fields {
  &>label {
    font-size: 0.9em;
  }
}

.ud-section-fields {
  // margin-top: 0.5em;
  //margin-bottom: 0.5em;
}

.ud-section-switches {
  padding: 1em;
  margin-top: 0.5em;
  // border : 1px grey solid;
  // border-radius : 5px;
}

.input-switch-container {
  margin: 1em 0 0.2em 0;
}

#ud-form input {
  padding: 0.3em 0.5em;
}

#profile-page-content {
  // padding: 0;
}

.fiche-description .prop-name .prop-description p b {
  font-size: 1.25em;
}

/*
.app-toolbar .app-toolbar-icon>div {
  display: flex;
  align-items: center;
}

.list-index-element-selected {
  color: $color1;
}

.event-page .start-date,
.event-page .start-time span {
  font-weight: 500;
}

.klh-title {
  background: none;
  width: auto;
  height: auto;
  font-size: 1em;
}

.fiche .activities-content>span:not(:last-child) {
  border-bottom: none;
}
*/

.goldensummit_categories {
  .list-component {
    display: flex;
    flex-flow: wrap;
  }

  .list-component li {
    display: flex;
    width: calc(50% - 2px);
    text-align: center;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    padding: 0;
    margin: 0;
    border: 1px solid transparent;
  }

  .list-component li:first-child {
    margin: 0;
  }

  .list-el-counter {
    display: flex;
    justify-content: center;
  }

  .list-el-chevron {
    display: none;
  }

  .list-component .itemWrapper {
    flex-grow: initial;
  }

  .list-el-counter span,
  .list-el-image,
  .list-component .star {
    margin-right: 0;
    margin-top: 15px;
  }

  .list-el-image {
    width: 200px;
  }
}

